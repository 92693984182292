import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import {
  retryWhen,
  delay,
  mergeMap,
  take,
  catchError,
  map,
} from 'rxjs/operators';
import { BootstrapNotifyService } from '../services/bootstrapNotify/bootstrap-notify.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements HttpInterceptor {
  constructor(
    private http: HttpClient,
    private router: Router,
    private bootstrapNotifyService: BootstrapNotifyService
  ) {
    // super(http);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token');

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      })
    );
  }

  // intercept and format all possible http error.
  private errorHandler(error: any) {
    try {
      console.log('error', error);
      // if ((error.error.code === '1008') && (error.error.description.includes('Invalid token or token expired'))) {
      if (
        error.code === 400 ||
        error.description === 'Invalid Email Address' ||
        'Invalid token or token expired'
      ) {
        // this.bootstrapNotifyService.error(error.error.error);
        // return;
        return throwError(
          error || { description: 'Invalid token or token expired' }
        );
      }
      return throwError(
        error || { description: 'Invalid token or token expired' }
      );
    } catch (error) {
      return throwError(
        error || { description: 'Invalid token or token expired' }
      );
    }
  }
  // in case of Login: this will art as an interceptor to store the token return and possible login user data
  private decode(res: any, auth?: string | null) {
    const data = res;
    if (res) {
      // console.log('API_res', res, auth);
      if (auth && auth.match('login')) {
        sessionStorage.setItem(env.TOKEN, JSON.stringify(data.accessToken));
        // console.log('MyToken', data.accessToken);
      }
      return res;
    } else {
      return res;
    }
  }
  // handles all delete api request
  public deleteRequest(
    api: string,
    path: string | null,
    data?: any
  ): Observable<any> {
    let ENDPOINT = `${env.API_URL}/${api}`;
    if (path) {
      ENDPOINT = `${env.API_URL}/${api}/${path}`;
    } else {
      ENDPOINT = `${env.API_URL}/${api}`;
    }

    // Always provide an options object, even if it's empty
    const options = {
      headers: new HttpHeaders(),
      body: data,
    };

    return this.http.delete(ENDPOINT, options).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((err) => this.errorHandler(err)),
          delay(1000),
          take(2)
        );
      }),
      catchError(this.errorHandler),
      map((res) => {
        return res;
      })
    );
  }
  // handles all update api request
  public putRequest(
    api: string,
    path: string | null,
    data: any
  ): Observable<any> {
    let ENDPOINT = `${env.API_URL}/${api}`;
    if (path) {
      ENDPOINT = `${env.API_URL}/${api}/${path}`;
    } else {
      ENDPOINT = `${env.API_URL}/${api}`;
    }
    return this.http
      .put(ENDPOINT, data)
      .pipe(
        retryWhen((errors) => {
          return errors.pipe(
            mergeMap((err) => this.errorHandler(err)),
            delay(1000),
            take(2)
          );
        })
      )
      .pipe(
        catchError(this.errorHandler),
        map((res) => {
          return res;
        })
      );
  }
  // handles all patching api request
  public patchRequest(
    api: string,
    path: string | null,
    data: any
  ): Observable<any> {
    let ENDPOINT = `${env.API_URL}/${api}`;
    if (path) {
      ENDPOINT = `${env.API_URL}/${api}/${path}`;
    } else {
      ENDPOINT = `${env.API_URL}/${api}`;
    }
    return this.http
      .patch(ENDPOINT, data)
      .pipe(
        retryWhen((errors) => {
          return errors.pipe(
            mergeMap((err) => this.errorHandler(err)),
            delay(1000),
            take(2)
          );
        })
      )
      .pipe(
        catchError(this.errorHandler),
        map((res) => {
          return res;
        })
      );
  }
  // handles all get / list api request
  public getRequest(
    api: string,
    path?: string | null,
    params?: HttpParams
  ): Observable<any> {
    let ENDPOINT = `${env.API_URL}/${api}`;

    if (path) {
      ENDPOINT = `${env.API_URL}/${api}/${path}`;
    } else {
      ENDPOINT = `${env.API_URL}/${api}`;
    }

    // Check if params is undefined, if so, create an empty HttpParams object
    const httpParams = params || new HttpParams();

    return this.http
      .get(ENDPOINT, { params: httpParams })
      .pipe(
        retryWhen((errors: any) => {
          return errors.pipe(
            mergeMap((err) => this.errorHandler(err)),
            delay(1000),
            take(2)
          );
        })
      )
      .pipe(
        catchError(this.errorHandler),
        map((res) => {
          // console.log('getRequest2', res);
          return res;
        })
      );
  }

  // Updated postRequest method to accept options
  public postRequest(
    api: string,
    path: string | null,
    data: any,
    options?: { headers?: HttpHeaders }
  ): Observable<any> {
    let ENDPOINT = `${env.API_URL}/${api}`;

    if (path) {
      ENDPOINT = `${env.API_URL}/${api}/${path}`;
    }

    // Apply options if provided, otherwise create an empty object
    const httpOptions = options || {};

    return this.http.post(ENDPOINT, data, httpOptions).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((err) => this.errorHandler(err)),
          delay(1000),
          take(2)
        );
      }),
      catchError(this.errorHandler),
      map((res) => this.decode(res, path))
    );
  }

  public postRequestPass(
    api: string,
    path: string | null,
    data: any,
    options?: { headers?: HttpHeaders }
  ): Observable<any> {
    let ENDPOINT = `${api}`;

    if (path) {
      ENDPOINT = `${env.API_URL}/${api}/${path}`;
    }

    // Apply options if provided, otherwise create an empty object
    const httpOptions = options || {};

    return this.http.post(ENDPOINT, data, httpOptions).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((err) => this.errorHandler(err)),
          delay(1000),
          take(2)
        );
      }),
      catchError(this.errorHandler),
      map((res) => this.decode(res, path))
    );
  }

  // public postAccountRequest(api: string, path: null | string, data: any): Observable<any> {
  //   let ENDPOINT = `${env.TRANSACTION_URL}/${api}`;

  //   if (path && path !== 'login') {
  //     ENDPOINT = env.TRANSACTION_URL + '/' + api + '/' + path;
  //   } else {
  //     ENDPOINT = env.TRANSACTION_URL + '/' + api;
  //   }

  //   return this.http
  //     .post(ENDPOINT, data)
  //     .pipe(
  //       retryWhen((errors) => {
  //         return errors.pipe(
  //           mergeMap((err) => this.errorHandler(err)),
  //           delay(1000),
  //           take(2)
  //         );
  //       })
  //     )
  //     .pipe(
  //       catchError(this.errorHandler),
  //       map((res) => this.decode(res, path))
  //     );
  // }
}
