import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { AppState } from 'src/app/store/app.state';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  emailURL = environment.API_URL;

  headers = {
    'Content-Type': 'application/json',
  };

  formHeaders = {
    'Content-Type': 'multipart/form-data',
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private store: Store,
    private tokenService: TokenService // Inject TokenService
  ) {}

  // Helper method to get headers with Authorization
  private getAuthHeaders(): HttpHeaders {
    const token = this.tokenService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
  }

  googleRequest(endpoint: string, options?: any): Observable<any> {
    const url = `${this.emailURL}/${endpoint}`;
    return this.http.get(url, options);
  }

  getGmailPermission(data: any): Observable<HttpResponse<any>> {
    return this.googleRequest('emails/gmail-init?email=' + data, {
      observe: 'response',
    }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  searchInbox(email: any, query: string): Observable<any> {
    return this.api.getRequest(
      'emails/search-emails?email=' + email + '&query=' + query
    );
  }

  getInboxEmails(data: any, page: string): Observable<any> {
    return this.api.getRequest(
      'emails/emails-list?email=' + data + '&pageToken=' + page
    );
  }

  getInboxEmailsNextPrevPage(data: any, page: string): Observable<any> {
    return this.api.getRequest(
      'emails/emails-list?email=' + data + '&pageToken=' + page
    );
  }

  // getDraftEmails(data: any): Observable<any> {
  //   return this.api.getRequest('emails/get-draft-emails?email=' + data);
  // }

  // getSentEmails(data: any): Observable<any> {
  //   return this.api.getRequest('emails/get-sent-emails?email=' + data);
  // }

  getOtherEmailCategories(data: any, category: string): Observable<any> {
    return this.api.getRequest(
      'emails/view-emails?email=' + data + '&type=' + category
    );
  }

  getEmailTemplates(): Observable<any> {
    return this.api.getRequest('setup/getemailtemplates');
  }

  summarizeAttachments(data: any): Observable<any> {
    return this.api.postRequest(
      'costaffai/summarize-email-attachment',
      null,
      data
    );
  }

  generateEmail(data: any): Observable<any> {
    // return this.api.postRequest('emails/generate-email', null, data);
    return this.api.postRequest('costaffai/generate-draft-email', null, data);
  }

  generateUsingTemplateEmail(data: any): Observable<any> {
    return this.api.postRequest(
      'emails/generate-using-template-email',
      null,
      data
    );
  }

  trashEmails(data: any): Observable<any> {
    return this.api.postRequest('emails/trash-email', null, data);
  }

  markEmailsRead(data: any): Observable<any> {
    return this.api.postRequest('emails/mark-as-read', null, data);
  }

  archiveEmails(data: any): Observable<any> {
    return this.api.postRequest('emails/archive-email', null, data);
  }

  sendEmails(data: any): Observable<any> {
    return this.api.postRequest('emails/send-email', null, data);
  }

  getDraftEmails(data: any): Observable<any> {
    return this.api.getRequest('emails/get-draft-emails?email=' + data);
  }

  getSentEmails(data: any): Observable<any> {
    return this.api.getRequest('emails/get-sent-emails?email=' + data);
  }

  speechToText(data: any): Observable<any> {
    return this.api.postRequest('transcribe/speech-to-text', null, data);
  }

  disconnectEmails(data: any): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.api.postRequest('emails/disconnect-email', null, data, {
      headers,
    });
  }
}
